import React, { createContext, useMemo } from 'react';
import type { ReactNode } from 'react';

import { graphql, useStaticQuery } from 'gatsby';

const dataProviderQuery = graphql`
  query dataProviderQuery {
    allPrismicEegsGame(filter: { data: { published: { eq: true } } }) {
      edges {
        node {
          uid
          data {
            title
            thumbnail {
              alt
              localFile {
                childrenImageSharp {
                  webp: resize(toFormat: WEBP) {
                    src
                  }
                }
              }
            }
            good_to_know {
              icon
              title_text
              link {
                url
                target
                link_type
              }
              description_text {
                richText
              }
            }
            game {
              game_id
              accessibility_report
              accessibility_tags
              added_date
              adverts
              game_title
              games_rating_authority_url
              generated_players_text
              genre_list
              id
              in_app_purchase
              maximum_players_locally
              maximum_players_note
              maximum_players_online
              minimum_players_locally
              newest_release_date
              original_release_date
              overview
              pegi_descriptors
              pegi_rating
              pegi_ratings_text
              platform_list
              positive_gameplay
              play_styles
              skill_age_from
              skill_age_text
              subscription
              trailer_video_url
              updated_date
            }
          }
        }
      }
    }
  }
`;

type PackedGameData = {
  d: string;
  id: string[];
  uid: string[];
  c: string[];
  h: string[];
  s: {
    [x: string]: (string | null)[];
  };
  o: {
    [x: string]: Record<string, number[]>;
  };
  i: unknown /* not currently used */;
};

export type GameData = {
  id: string;
  uid: string;
  cover_uri: string;
  game_title: string;
  original_release_date: string;
  newest_release_date: string | null;
  platform_list: string[];
  accessibility_tags: string[];
  pegi_rating: string | null;
  pegi_descriptors: string[];
  skill_age_from: string | null;
  positive_gameplay: string[];
  play_styles: string[];
  in_app_purchase: boolean | null;
  adverts: boolean | null;
  subscription: boolean | null;
  single_player: boolean;
  multi_player: boolean;
  online_only: boolean;
  offline_only: boolean;
};

export type UnpackedGameData = {
  games: {
    [uid: string]: GameData;
  };
  index: unknown;
};

export type DataContextData = {
  data: UnpackedGameData | undefined;
};

export const DataContext = createContext<DataContextData>({
  data: undefined,
});

const dataList = (data: Record<string, number[]>, id: number | string) =>
  (typeof data === 'object' && Object.keys(data).filter((k) => data[k].includes(Number(id)))) || [];

const singleEntryList = (data: Record<string, number[]>, id: number | string) =>
  (typeof data === 'object' &&
    Object.keys(data).filter((k) => data[k].includes(Number(id)))?.[0]) ||
  null;

const booleanNullEntry = (data: Record<string, number[]>, id: number | string) => {
  const value = singleEntryList(data, id);
  if (value === 'Yes') {
    return true;
  } else if (value === 'No') {
    return false;
  } else {
    return null;
  }
};

/* originally from eegs-api  - left as packed/unpacked to reduce code change */

const processData = (
  data: Queries.dataProviderQueryQuery['allPrismicEegsGame']['edges'],
): PackedGameData => {
  const game_id: Array<string> = [];
  const game_uid: Array<string> = [];
  const game_cards: Array<string> = [];

  const game_objects: Record<string, Record<string, Array<number>>> = {
    platform_list: {},
    accessibility_tags: {},
    pegi_rating: {},
    pegi_descriptors: {},
    skill_age_from: {},
    positive_gameplay: {},
    play_styles: {},
    in_app_purchase: {},
    adverts: {},
    subscription: {},
  };

  const game_strings: Record<string, Array<string | null>> = {
    game_title: [],
    original_release_date: [],
    newest_release_date: [],
  };

  const game_objects_other: Record<string, Record<string, Array<number>>> = {
    players: {
      single_player: [],
      multi_player: [],
      online_only: [],
      offline_only: [],
    },
  };

  const game_hashes: Array<string> = [];

  for (const n in data) {
    const entry = data[n].node;

    if (entry.data.game != null && entry.data.game.game_id) {
      game_id[Number(n)] = entry.data.game.game_id;
      game_uid[Number(n)] = entry.uid;
      game_cards[Number(n)] =
        entry.data.thumbnail?.localFile?.childrenImageSharp?.[0]?.webp?.src || '';
      if (entry.data.game?.platform_list) {
        for (const key of Object.keys(game_objects)) {
          if (key in entry.data.game) {
            for (const val of [entry.data.game[key as keyof typeof entry.data.game]].flat()) {
              if (val) {
                if (!game_objects[key][val]) {
                  game_objects[key][val] = [Number(n)];
                } else {
                  game_objects[key][val].push(Number(n));
                }
              }
            }
          }
        }
        for (const key of Object.keys(game_strings)) {
          const s = entry.data.game[key as keyof typeof entry.data.game];
          if (typeof s === 'string') {
            game_strings[key][Number(n)] = s;
          } else {
            game_strings[key][Number(n)] = null;
          }
        }
        if (
          Number(entry.data.game.maximum_players_locally) > 1 ||
          Number(entry.data.game.maximum_players_online) > 1
        ) {
          game_objects_other.players.multi_player.push(Number(n));
        }
        if (Number(entry.data.game.maximum_players_locally) === 1) {
          game_objects_other.players.single_player.push(Number(n));
        }
        if (Number(entry.data.game.maximum_players_locally) === 0) {
          game_objects_other.players.online_only.push(Number(n));
        }
        if (Number(entry.data.game.maximum_players_online) === 0) {
          game_objects_other.players.offline_only.push(Number(n));
        }
      }
    }
  }

  return {
    d: new Date().toISOString(),
    id: game_id,
    uid: game_uid,
    c: game_cards,
    h: game_hashes,
    s: { ...game_strings },
    o: { ...game_objects, ...game_objects_other },
    i: null,
  };
};

const unpackData = (data: PackedGameData): UnpackedGameData => {
  const games: UnpackedGameData['games'] = {};

  for (const n in data.uid) {
    games[data.uid[n]] = {
      id: data.id[n],
      uid: data.uid[n],
      // cover_uri: data.c[n],
      cover_uri: `${process.env.GATSBY_CF_PREFIX || ''}/g/s/${data.id[n]}/icon.webp`,
      game_title: data.s.game_title[n] || '',
      original_release_date: data.s.original_release_date[n] || '',
      newest_release_date: data.s.newest_release_date[n] || null,
      platform_list: dataList(data.o.platform_list, n),
      accessibility_tags: dataList(data.o.accessibility_tags, n),
      pegi_rating: singleEntryList(data.o.pegi_rating, n),
      pegi_descriptors: dataList(data.o.pegi_descriptors, n),
      skill_age_from: singleEntryList(data.o.skill_age_from, n),
      positive_gameplay: dataList(data.o.positive_gameplay, n),
      play_styles: dataList(data.o.play_styles, n),
      in_app_purchase: singleEntryList(data.o.in_app_purchase, n) === 'Yes',
      adverts: booleanNullEntry(data.o.adverts, n),
      subscription: booleanNullEntry(data.o.subscription, n),
      single_player: dataList(data.o.players, n).includes('single_player'),
      multi_player: dataList(data.o.players, n).includes('multi_player'),
      online_only: dataList(data.o.players, n).includes('online_only'),
      offline_only: dataList(data.o.players, n).includes('offline_only'),
    };
  }

  return {
    games: games,
    index: data.i,
  };
};

interface DataProviderProps {
  children: ReactNode;
}
const DataProvider = ({ children }: DataProviderProps) => {
  const data: Queries.dataProviderQueryQuery = useStaticQuery(dataProviderQuery);

  const gameData = useMemo(
    () => ({ data: unpackData(processData(data.allPrismicEegsGame.edges)) }),
    [data],
  );
  return <DataContext.Provider value={gameData}>{children}</DataContext.Provider>;
};

export default DataProvider;
